<template>
  <main-layout>
    <template slot="content">
      <v-container>
        <v-row>
          <v-spacer></v-spacer>
          <v-col class="col-12 col-md-7 pa-0">
            <v-card>
              <checkout-dialog v-if="checkoutDialog" v-model="checkoutDialog"/>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <login v-if="type === 'login'" :show-links="true" @show-checkout="checkoutDialog = true" @login="login"></login>
                    <forgot-password v-if="type === 'forgot'"></forgot-password>
                    <restore-password v-if="type === 'reset'"></restore-password>
                    <restore-user v-if="type === 'restore'"></restore-user>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </template>
  </main-layout>
</template>
<script>
import MainLayout from "@/layouts/MainLayout";
import Login from "@/components/Login";
import ForgotPassword from "@/components/ForgotPassword";
import RestorePassword from "@/components/RestorePassword";
import RestoreUser from "@/components/RestoreUser";
import CheckoutDialog from "@/components/CheckoutDialog";

export default {
  name: "Authorization",
  props: {
    type: {
      type: String,
      default: "login",
    },
  },
  data() {
    return {
      checkoutDialog: false,
    };
  },
  components: {
    CheckoutDialog,
    MainLayout,
    Login,
    ForgotPassword,
    RestorePassword,
    RestoreUser,
  },
  methods: {
    login() {
      window.history.back();
      setTimeout(() => window.location.reload(), 1000);
    },
  },
};
</script>
